import axios from 'axios'

export const UserApi = {
    async Table(data){
        const response = await axios.post('/api/user/table',data)
        return response
    },
    async CreateOrUpdate(update,data){
        const response = await axios.post(update ? '/api/user/update' : '/api/user/create' , data)
        return response
    },
    async Delete(data){
        const response = await axios.post(`/api/user/delete/${data}`)
        return response
    },
    async Disable(data){
        const response = await axios.post(`/api/user/disable/${data}`)
        return response
    },
    async ListWithoutSelectedUser(data){
        const response = await axios.post(`/api/user/list/withoutselecteduser`,data)
        return response
    },
    async List(){
        const response = await axios.get(`/api/user/salesman/list`)
        return response
    },
    async ResendConfirmationCode(data){
        const response = await axios.post(`/api/user/resendconfirmationcode/${data}`)
        return response
    },
    async Confirm(code){
        const response = await axios.post(`/api/user/confirm/${code}`)
        return response
    },
    async CurrentUserProfile(){
        const response = await axios.get(`/api/user/get/currentuserinformation`)
        return response
    },
    async UpdatePassword(data){
        const response = await axios.post('/api/user/update/password',data)
        return response
    },
    async SalesmanList(){
        const response = await axios.get(`/api/user/salesman/list`)
        return response
    }
}